<template>
    <v-layout wrap>
        <v-flex xs12 ml-3>
            <v-switch v-model="item.isDeliveryByBike" :label="$t('Vai utilizar bicicleta?')" class="my-0 py-0" />
        </v-flex>
        <v-flex xs12 ml-3>
            <v-layout wrap>
                <v-flex xs12 md4 lg3>
                    <v-switch v-model="item.isDeliveryByMotorcycle" :label="$t('Vai utilizar moto?')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12 md4 lg3>
                    <v-switch v-model="item.hasMotorcycle" :label="$t('Possui moto própria?')" class="my-0 py-0" />
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex xs12 ml-3>
            <v-layout wrap>
                <v-flex xs12 md4 lg3>
                    <v-switch v-model="item.isDeliveryByCar" :label="$t('Vai utilizar carro?')" class="my-0 py-0" />
                </v-flex>
                <v-flex xs12 md4 lg3>
                    <v-switch v-model="item.hasCar" :label="$t('Possui carro próprio?')" class="my-0 py-0" />
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex xs12 v-if="needCNH">
            <v-card color="grey lighten-4" class="mb-4">
                <v-card-title class="text-h5 primary--text"> Dados da CNH </v-card-title>
                <v-card-subtitle>
                    <v-layout wrap elevation="12">
                        <v-flex xs12 sm6 lg4>
                            <v-text-field inputmode="numeric" :label="$t('Número da CNH')" v-model="item.driverLicense" v-mask="'###########'"
                                :rules="[needCNHRule, rules.driverLicense]" />
                        </v-flex>
                        <v-flex xs12 sm6 lg4>
                            <v-text-field inputmode="numeric" :label="$t('Data de emissão da CNH')" v-model="driverLicenseIssueDate"
                                :rules="[needCNHRule, rules.date, rules.rangeDate('DD/MM/YYYY', $moment().subtract({ year: 100 }), $moment())]" v-mask="'##/##/####'" />
                        </v-flex>
                        <v-flex xs12 sm6 lg4>
                            <v-text-field inputmode="numeric" :label="$t('Data de vencimento da CNH')" v-model="driverLicenseExpirationDate"
                                :rules="[needCNHRule, rules.date, rules.rangeDate('DD/MM/YYYY', $moment().subtract({ year: 1 }), $moment().add({ year: 10 }))]"
                                v-mask="'##/##/####'" />
                        </v-flex>
                        <v-flex xs12>
                            <h2>Categoria da CNH {{ this.item.driverLicenseType }}</h2>
                            <v-container>
                                <v-row>
                                    <v-switch v-model="driverLicenseTypeA" value="A" label="A" class="mr-12" />

                                    <v-switch v-model="driverLicenseTypeBCD" value="B" label="B" class="mr-4" />
                                    <v-switch v-model="driverLicenseTypeBCD" value="C" label="C" class="mr-4" />
                                    <v-switch v-model="driverLicenseTypeBCD" value="D" label="D" class="mr-4" />
                                </v-row>
                            </v-container>
                        </v-flex>
                    </v-layout>
                </v-card-subtitle>
            </v-card>
        </v-flex>
        <v-flex xs12 md6 v-if="item.hasMotorcycle">
            <v-card color="grey lighten-4" class="mb-4">
                <v-card-title class="text-h5 primary--text"> <v-icon large color="primary" class="mr-4"> two_wheeler </v-icon>Cadastro de moto </v-card-title>
                <v-card-subtitle>
                    <v-layout wrap elevation="12">
                        <v-flex xs12>
                            <v-subheader class="pl-0"> {{ $t("Ano de fabricação da moto") }} </v-subheader>
                            <v-slider class="mx-4 mt-8" :thumb-size="32" thumb-label="always" v-model="item.yearOfManufacture" :min="startYear" :max="endYear" />
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete v-model="item.brand" :items="brandsMotorcycle" item-text="Label" item-value="Label" :label="$t('Marca da moto')"
                                :rules="[ifHasMotorcycleRule]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :label="$t('Modelo da moto')" v-model="item.model" :rules="[ifHasMotorcycleRule, rules.min(5)]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :label="$t('Placa')" v-model="item.plate" v-mask="'AAA#X##'" :rules="[rules.brazilianPlate]" />
                        </v-flex>
                    </v-layout>
                </v-card-subtitle>
            </v-card>
        </v-flex>

        <v-flex xs12 md6 v-if="item.hasCar">
            <v-card color="grey lighten-4 primary--text" class="mb-4">
                <v-card-title class="text-h5"> <v-icon large color="primary" class="mr-4"> local_shipping </v-icon>Cadastro de carro </v-card-title>
                <v-card-subtitle>
                    <v-layout wrap elevation="12">
                        <v-flex xs12>
                            <v-subheader class="pl-0"> {{ $t("Ano de fabricação do carro") }} </v-subheader>
                            <v-slider class="mx-4 mt-8" :thumb-size="32" thumb-label="always" v-model="item.carYearOfManufacture" :min="startYear" :max="endYear" />
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete v-model="item.carType" :items="carTypes" :label="$t('Categoria')" :rules="[ifHasCarRule]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete v-model="item.carBrand" :items="brandsCar" :label="$t('Marca do carro')" :rules="[ifHasCarRule]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :label="$t('Modelo do carro')" v-model="item.carModel" :rules="[ifHasCarRule, rules.min(5)]" />
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field :label="$t('Placa')" v-model="item.carPlate" v-mask="'AAA#X##'" :rules="[rules.brazilianPlate]" />
                        </v-flex>
                    </v-layout>
                </v-card-subtitle>
            </v-card>
        </v-flex>

        <v-flex xs12>
            <v-alert icon="warning" prominent text dense type="warning" v-if="needCNHMotorcycle && !driverLicenseTypeA"> Selecionado meio de transporte de moto porém CNH não
                marcada
                para categoria A </v-alert>
            <v-alert icon="warning" prominent text dense type="warning" v-if="needCNHCar && !driverLicenseTypeBCD"> Selecionado meio de transporte de carro porém CNH não
                possui categoria B/C/D </v-alert>
        </v-flex>

    </v-layout>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import brandsMotorcycle from "@/assets/json/brandsMotorcycle.json";
import brandsCar from "@/assets/json/brandsCar.json";
import carTypes from "@/assets/json/carTypes.json";

export default {
    directives: { mask },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            rules,
            brandsMotorcycle,
            brandsCar,
            carTypes,
            driverLicenseTypeA: "",
            driverLicenseTypeBCD: "",
            needCNHRule: (value) =>
                (!!value && this.needCNH) ||
                (!this.needCNH) ||
                this.$t("Obrigatório."),

            ifHasMotorcycleRule: (value) => (!!value && this.item.hasMotorcycle) || !this.item.hasMotorcycle || this.$t("Obrigatório."),
            ifHasCarRule: (value) => (!!value && this.item.hasCar) || !this.item.hasCar || this.$t("Obrigatório."),
            activePicker1: null,
            menu1: false,
            activePicker2: null,
            menu2: false,
        };
    },

    watch: {
        driverLicenseTypeA() {
            this.item.driverLicenseType = (this.driverLicenseTypeA ? this.driverLicenseTypeA : "") + (this.driverLicenseTypeBCD ? this.driverLicenseTypeBCD : "");
        },
        driverLicenseTypeBCD() {
            this.item.driverLicenseType = (this.driverLicenseTypeA ? this.driverLicenseTypeA : "") + (this.driverLicenseTypeBCD ? this.driverLicenseTypeBCD : "");
        },
        item: {
            immediate: true,
            handler(newVal) {
                if (newVal == null || !newVal.driverLicenseType) {
                    this.driverLicenseTypeA = "";
                    this.driverLicenseTypeBCD = "";
                } else {
                    this.driverLicenseTypeBCD =
                        newVal.driverLicenseType.length > 1
                            ? newVal.driverLicenseType.substring(1, 2)
                            : newVal.driverLicenseType.length > 0
                                ? newVal.driverLicenseType.substring(0, 1)
                                : "";
                    this.driverLicenseTypeA = newVal.driverLicenseType && newVal.driverLicenseType.length > 0 && newVal.driverLicenseType.substring(0, 1) == "A" ? "A" : "";
                }
            },
        },
    },

    computed: {
        needCNH() {
            return this.needCNHCar || this.needCNHMotorcycle
        },
        needCNHCar() {
            return this.item.isDeliveryByCar || this.item.hasCar
        },
        needCNHMotorcycle() {
            return this.item.isDeliveryByMotorcycle || this.item.hasMotorcycle
        },
        driverLicenseIssueDate: {
            get: function () {
                return this.item.driverLicenseIssueDate ? this.$moment(this.item.driverLicenseIssueDate).format("DD/MM/YYYY") : "";
            },
            set: function (data) {
                if (data && data.length == 10) {
                    this.item.driverLicenseIssueDate = this.$moment(data, "DD/MM/YYYY");
                }
            },
        },
        driverLicenseExpirationDate: {
            get: function () {
                return this.item.driverLicenseExpirationDate ? this.$moment(this.item.driverLicenseExpirationDate).format("DD/MM/YYYY") : "";
            },
            set: function (data) {
                if (data && data.length == 10) {
                    this.item.driverLicenseExpirationDate = this.$moment(data, "DD/MM/YYYY");
                }
            },
        },
        startYear() {
            return new Date().getFullYear() - 20;
        },
        endYear() {
            return new Date().getFullYear() + 1;
        },
    },
};
</script>